import { Link } from "gatsby";
import React, { useContext } from "react";
import { CartContext } from "../../contexts";

import Item from "./Item";

export default function CartItems() {
  const { cart } = useContext(CartContext);
  return (
    <div className="flex flex-col lg:w-2/3">
      {cart.length !== 0 ? (
        cart.map((item) => {
          return <Item item={item} key={item.type.strapiId} />;
        })
      ) : (
        <div className="flex flex-col lg:h-40 items-center justify-center space-y-6 p-6">
          <h2>Сложите в корзину нужные товары</h2>
          <h4>
            А чтобы их найти, загляните в каталог или в раздел со скидками
          </h4>
          <div className="flex space-x-5">
            <Link to="/new" className="p-3 bg-green-400 hover:bg-green-500 rounded-md font-medium text-white">
              Скидки и акции
            </Link>
            <div className="p-3 font-medium">
              <a href="/">
                <span className="hover:border-b border-b hover:border-gray-300">В каталог</span>
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
