import React, { useContext } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { TrashIcon } from "@heroicons/react/outline";
import { CartContext } from "../../contexts";
import { removeFromCart } from "../../contexts/actions";
import { toCurrency } from "../product-detail/ProductInfo";
import QtyButton from "../product-list/QtyButton";

export default function Item({ item }) {
  const { dispatchCart } = useContext(CartContext);
  const handleDelete = () => {
    dispatchCart(removeFromCart(item.type, item.qty));
  };
  const actions = [{ icon: <TrashIcon />, onClick: handleDelete }];
  const img = item.type?.images[0]
    ? item.type.images[0].localFile.childImageSharp.gatsbyImageData
    : null;

  return (
    <div className="flex flex-col lg:grid lg:grid-cols-6 space-y-4 lg:space-y-0 justify-between lg:gap-4 py-4">
      <div className="flex space-x-4 lg:col-span-4">
        <div className="w-1/4">
          <a href={`/product/${item.type.strapiId}`}>
            {item.type?.images[0] ? (
              <GatsbyImage
                image={img}
                title={item.type.name}
                alt={item.type.name}
              />
            ) : (
              <div className="h-52 flex ">
                <StaticImage
                  src="../../images/noimg.jpg"
                  className="self-center"
                  title={item.type.name}
                  alt={item.type.name}
                />
              </div>
            )}
          </a>
        </div>
        <div className="lg:text-sm w-3/4 self-center flex flex-col space-y-4">
          <div className="text-gray-500 italic text-sm">
            Арт: {item.type.code}
          </div>
          <span>
            <a
              href={`/product/${item.type.strapiId}`}
              className="hover:underline"
            >
              {item.name}
            </a>
          </span>
          <span className="font-semibold font-header text-xl">
            {toCurrency(item.type.price * item.qty)}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-3 lg:grid-cols-2 lg:col-span-2 bg-gray-50 lg:bg-white py-4 lg:py-0">
        <div className="col-start-2 lg:col-start-1 flex items-center justify-center">
          {actions.map((action) => {
            return (
              <button
                type="button"
                key={action}
                onClick={() => action.onClick()}
                className="lg:w-6 w-8 text-gray-400 hover:text-red-600"
              >
                {action.icon}
              </button>
            );
          })}
        </div>
        <div className="flex items-center justify-center rounded-2xl">
          <QtyButton
            isCart
            name={item.name}
            selectedVariant={0}
            types={[item.type]}
          />
        </div>
      </div>
    </div>
  );
}
